import * as React from "react"
import {ArticlePreview, ArticlePreviewProps} from "../ArticlePreview"
import PageTitle from "../PageTitle"

export const ArticleList: React.SFC<ArticleListProps> = ({ articles, title }) => (
    <div id="articles" className="mt-10 lg:mx-6 lg:w-8/12 w-full flex-grow">
        {title && (
            <div className="mb-8 w-100">
                <PageTitle>{title}</PageTitle>
            </div>
        )}
        {articles.map((props, i) => (
            <ArticlePreview key={i} {...props} />
        ))}
    </div>
)

export default ArticleList

export interface ArticleListProps {
    articles: ArticlePreviewProps[]
    title?: string
}
