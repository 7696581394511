import * as React from "react"
import { Link } from "gatsby"
import "./style.css"

export const ArticlePreview: React.FC<ArticlePreviewProps> = ({ title, url, blurb, date }) => (
  <Link className="" to={url}>
    <article className="
            article-preview
            p-2
            md:p-4
            mb-6
            border-gray-200
            border
            border-solid
            shadow
            rounded-xl
            opacity-90
            transform
            light-shadow
            hover:shadow-xl
            hover:-translate-y-0.5
            hover:opacity-100">
      <h2 className="text-xl md:text-2xl">{title}</h2>
      <time className="text-xs italic" dateTime={date}>{date}</time>
      <section className="text-l text-gray-700 dark:text-gray-300 mt-4" dangerouslySetInnerHTML={{ __html: blurb }} />
    </article>
  </Link>
)

export default ArticlePreview

export interface ArticlePreviewProps {
  title: string
  url: string
  blurb: string
  date: string
}
